//
// Interactive Map
// Based on https://github.com/Leaflet/Leaflet
// --------------------------------------------------


.interactive-map:focus {
  outline: none;
}


// Zoom controls

.leaflet-touch {
  .leaflet-control-layers,
  .leaflet-bar {
    border: 0;
    border-radius: var(--#{$prefix}border-radius-sm);
    box-shadow: $box-shadow;
  }
  .leaflet-bar a {
    font-weight: $font-weight-normal;
    line-height: 26px;
    color: $gray-800;
    transition: $nav-link-transition;
    &:first-child {
      border-bottom-color: $border-color;
      border-top-left-radius: var(--#{$prefix}border-radius-sm);
      border-top-right-radius: var(--#{$prefix}border-radius-sm);
    }
    &:last-child {
      border-bottom-right-radius: var(--#{$prefix}border-radius-sm);
      border-bottom-left-radius: var(--#{$prefix}border-radius-sm);
    }
    &:hover {
      color: $nav-link-hover-color;
      background-color: $white;
    }
    &.leaflet-disabled { // stylelint-disable-line selector-no-qualifying-type
      color: rgba($gray-600, .8);
      background-color: $white;
    }
  }
}

.leaflet-left .leaflet-control {
  margin-left: $spacer;
}
.leaflet-top .leaflet-control {
  margin-top: $spacer;
}


// Link color

.leaflet-container a {
  font-weight: $font-weight-medium;
  color: var(--#{$prefix}primary);
}


// Popup

.leaflet-popup-content-wrapper {
  min-width: 17.5rem;
  padding: 0;
  overflow: hidden;
  border-radius: var(--#{$prefix}border-radius-lg);
  .leaflet-popup-content {
    width: 100% !important; // stylelint-disable-line declaration-no-important
    margin: 0 !important; // stylelint-disable-line declaration-no-important
    color: var(--#{$prefix}body-color);
  }
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0 .1875rem .875rem rgba($gray-900, .32);
}
.leaflet-container {
  font-family: $font-family-sans-serif;
  a.leaflet-popup-close-button { // stylelint-disable-line selector-no-qualifying-type
    top: .5rem;
    right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    font-size: 1rem;
    line-height: 1.25rem;
    color: rgba($gray-800, .7);
    text-align: center;
    border: $border-width solid $border-color;
    border-radius: 50%;
    &:hover { color: $gray-800; }
  }
}
.leaflet-popup:focus,
.leaflet-marker-icon:focus { outline: none; }
.leaflet-popup-tip-container {
  backface-visibility: hidden;
}
@-moz-document url-prefix() {
  .leaflet-container a.leaflet-popup-close-button { // stylelint-disable-line selector-no-qualifying-type
    line-height: 1.375rem;
  }
}


// Dark color mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      background: $gray-900;
    }
    .leaflet-container a.leaflet-popup-close-button { // stylelint-disable-line selector-no-qualifying-type
      color: rgba($white, .65);
      border-color: rgba($white, .18);
      &:hover { color: $white; }
    }
  }
}
