//
// Breadcrumb
// --------------------------------------------------


.breadcrumb {
  --#{$prefix}breadcrumb-color: #{$breadcrumb-color};
  --#{$prefix}breadcrumb-font-weight: #{$breadcrumb-font-weight};
  @include rfs($breadcrumb-divider-font-size, --#{$prefix}breadcrumb-divider-font-size);
  --#{$prefix}breadcrumb-item-hover-color: #{$breadcrumb-hover-color};

  font-weight: var(--#{$prefix}breadcrumb-font-weight);
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  + .breadcrumb-item::before {
    width: var(--#{$prefix}breadcrumb-divider-font-size);
    height: var(--#{$prefix}breadcrumb-divider-font-size);
    padding: 0;
    margin-right: var(--#{$prefix}breadcrumb-item-padding-x);
    content: "";
    background-color: currentcolor;
    -webkit-mask: escape-svg($breadcrumb-divider) no-repeat 50% 50%; // stylelint-disable-line property-no-vendor-prefix
    mask: escape-svg($breadcrumb-divider) no-repeat 50% 50%;
    -webkit-mask-size: cover; // stylelint-disable-line property-no-vendor-prefix
    mask-size: cover;
  }
  > a {
    display: flex;
    align-items: center;
    color: var(--#{$prefix}breadcrumb-color);
    text-decoration: none;
  }
  &:hover > a {
    color: var(--#{$prefix}breadcrumb-item-hover-color);
  }
}
