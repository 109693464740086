//
// Variables
// --------------------------------------------------

// Theme colors
$primary: #f5f5f7;

$body-bg: #121519;

$body-bg-dark: #000;

$btn-color: #f5f5f7;

$headings-color: #f5f5f7;

$headings-color-dark: #f5f5f7;

$body-color-dark: #f5f5f7;

$navbar-stuck-bg: #1d1d1f;

$form-check-input-checked-bg-color: $body-bg;

// Theme fonts
$font-family-sans-serif: "odx", sans-serif !default;
